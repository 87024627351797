import { useCompanyMutations } from "@app/entities/company/mutations";
import { useCompany } from "@app/providers/CompanyContextProvider";
import Form from "antd/lib/form";

export const useLowBalanceLoader = () => {
  const [lowBalanceForm] = Form.useForm();
  const { selectedCompany } = useCompany();

  const { updateLowBalance, isMutationPending } = useCompanyMutations();

  const handleUpdateLowBalance = async () => {
    const { lowBalanceThreshold, lowBalanceEmail } =
      lowBalanceForm.getFieldsValue();
    const lowBalanceData = {
      lowBalanceEmail,
      lowBalanceThreshold: lowBalanceThreshold * 100,
    };
    updateLowBalance(lowBalanceData);
  };

  const isLoading = isMutationPending;
  return {
    handleUpdateLowBalance,
    isLoading,
    lowBalanceForm,
    lowBalanceEmail: selectedCompany?.lowBalanceEmail,
    lowBalanceThreshold: selectedCompany?.lowBalanceThreshold,
  };
};
